import { visuallyHidden } from "@mui/utils";
import { LACK_OF_CAPACITY_DOCUMENT, LEGAL_GUARDIAN } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14 } from "ds_legacy/materials/typography";
import {
  PatientFormSubheading,
  Section,
  SectionRow,
  TextInputWithLabel,
  YesNoRadioWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionTwelve = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: LEGAL_GUARDIAN.STATUTORY_REP,
      value: LEGAL_GUARDIAN.STATUTORY_REP,
      label: translations.patientForms.drvGeneralForm.sectionEleven.legalRep,
    },
    {
      id: LEGAL_GUARDIAN.CUSTODIAN,
      value: LEGAL_GUARDIAN.CUSTODIAN,
      label: translations.patientForms.drvGeneralForm.sectionEleven.guardian,
    },
    {
      id: LEGAL_GUARDIAN.SUPERVISOR,
      value: LEGAL_GUARDIAN.SUPERVISOR,
      label: translations.patientForms.drvGeneralForm.sectionEleven.carer,
    },
    {
      id: LEGAL_GUARDIAN.AUTHORIZED,
      value: LEGAL_GUARDIAN.AUTHORIZED,
      label:
        translations.patientForms.drvGeneralForm.sectionEleven
          .authorisedRepresentative,
    },
  ];

  const optionsLackOfCapacity: RadioOptionV2[] = [
    {
      id: LACK_OF_CAPACITY_DOCUMENT.ATTACHED,
      value: LACK_OF_CAPACITY_DOCUMENT.ATTACHED,
      label: translations.patientForms.drvGeneralForm.sectionEleven.attached,
    },
    {
      id: LACK_OF_CAPACITY_DOCUMENT.SEND_LATER,
      value: LACK_OF_CAPACITY_DOCUMENT.SEND_LATER,
      label:
        translations.patientForms.drvGeneralForm.sectionEleven.submittedLater,
    },
  ];

  return (
    <Section
      title={translations.patientForms.drvGeneralForm.sectionEleven.title}
    >
      <PatientFormSubheading style={{ padding: padding(0, 0, 1) }}>
        {translations.patientForms.drvGeneralForm.sectionEleven.subtitle}
      </PatientFormSubheading>
      <YesNoRadioWithLabel
        elementName="third_party_request"
        label={translations.patientForms.drvGeneralForm.sectionEleven.subtitle}
        formLabelSx={visuallyHidden}
        startWithNo
      />
      <TextInputWithLabel
        elementName="contact_guardian"
        label={translations.patientForms.drvGeneralForm.sectionEleven.nameRep}
        marginOverride={margin(0, 0, 1)}
        labelWrapperSx={{ width: "100%" }}
      />
      <RadioGroupV2
        elementName="legal_guardian_type"
        label={
          translations.patientForms.drvGeneralForm.sectionEleven
            .legalGuardianType
        }
        options={options}
        formLabelSx={{
          padding: padding(1, 0),
          fontSize: FONT_SIZE_14,
        }}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
      <SectionRow>
        <TextInputWithLabel
          elementName="legal_guardian_house_number"
          label={translations.patientForms.drvGeneralForm.sectionEleven.street}
          large
        />
        <TextInputWithLabel
          elementName="legal_guardian_zip_code"
          label={translations.patientForms.drvGeneralForm.sectionEleven.zipCode}
        />
        <TextInputWithLabel
          elementName="legal_guardian_town"
          label={translations.patientForms.drvGeneralForm.sectionEleven.city}
          large
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          elementName="legal_guardian_phone"
          label={translations.patientForms.drvGeneralForm.sectionEleven.phone}
          large
        />
        <TextInputWithLabel
          elementName="legal_guardian_email"
          label={translations.patientForms.drvGeneralForm.sectionEleven.email}
          large
        />
      </SectionRow>
      <RadioGroupV2
        elementName="consent_of_the_insured"
        label={
          translations.patientForms.drvGeneralForm.sectionEleven.lackOfCapacity
        }
        options={optionsLackOfCapacity}
        formLabelSx={{
          padding: padding(3, 0, 1),
          fontSize: FONT_SIZE_14,
        }}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
    </Section>
  );
};
