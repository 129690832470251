import { handleKeyDown } from "core/model/accessibility/keyboardActions";
import Tooltip from "ds_legacy/components/Tooltip";
import { CHIP_BACKGROUND, configureColor } from "ds_legacy/materials/colors";
import {
  CHIP_HEIGHT,
  CIRCLE_BORDER_RADIUS,
  ROUND_BORDER_RADIUS,
  SQUARE_BORDER_RADIUS,
  border,
  dp,
  margin,
  padding,
} from "ds_legacy/materials/metrics";
import { Chip as ChipText } from "ds_legacy/materials/typography";
import React, { AriaRole, CSSProperties, ReactNode } from "react";
import styled from "styled-components";

export const IconCircle = styled.div<{
  backgroundColor?: string;
  border?: string;
}>`
  border-radius: ${CIRCLE_BORDER_RADIUS};
  height: ${dp(32)};
  width: ${dp(32)};
  margin: ${margin(0)};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  border: ${(props) => props.border};
  justify-content: center;
  align-items: center;
`;

export const ChipContainer = styled.div<{
  display?: CSSProperties["display"];
  margin?: CSSProperties["margin"];
}>`
  display: ${(props) => props.display || "block"};
  margin: ${(props) => props.margin ?? margin(1, 2, 1, 0)};
`;

export const ChipContainerMarginSmall = styled.div<{ display?: string }>`
  display: ${(props) => props.display || "block"};
  margin: ${margin(0.7, 0.7, 0.7, 0.7)};
`;

type ColorsType = {
  black?: boolean;
  disabled?: boolean;
  error?: boolean;
  primary?: boolean;
  white?: boolean;
};

const ChipBox = styled.div<{
  backgroundColor: ColorsType;
  borderColor: ColorsType;
  borderHoverColor: ColorsType;
  colorOverride?: string;
  height?: CSSProperties["minHeight"];
  hovered?: boolean;
  justifyContent?: CSSProperties["justifyContent"];
  margin?: string;
  opacity?: number;
  padding?: string;
  squared?: boolean;
  width?: CSSProperties["width"];
}>`
  word-break: break-all;
  box-sizing: border-box;
  border-radius: ${({ squared }) =>
    squared ? SQUARE_BORDER_RADIUS : ROUND_BORDER_RADIUS};
  background-color: ${(props) =>
    props.colorOverride && props.backgroundColor.primary
      ? props.colorOverride
      : configureColor(
          props.theme,
          props.backgroundColor,
          props.opacity ? "transparent" : CHIP_BACKGROUND,
          props.hovered,
        )};
  padding: ${(props) => props.padding || padding(0)};
  margin: ${(props) => props.margin};
  display: flex;
  align-items: center;
  min-height: ${({ height }) => height || dp(CHIP_HEIGHT)};
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  border: ${(props) =>
    border({
      color: props.colorOverride
        ? props.colorOverride
        : configureColor(props.theme, props.borderColor),
    })};
  opacity: ${({ opacity }) => opacity || 1};
  justify-content: ${({ justifyContent }) => justifyContent || undefined};
  width: ${({ width }) => width};
  &:hover {
    ${(props) =>
      props.onClick &&
      `border: ${border({
        color: configureColor(props.theme, props.borderHoverColor),
      })};`}
  }
  @media print {
    & {
      height: ${dp(22)};
    }
  }
`;

export type ChipProps = {
  bold?: boolean;
  children?: React.ReactNode;
  chipMargin?: string;
  chipPadding?: string;
  chipTextFontSize?: string;
  className?: string;
  colorOverride?: string;
  disabled?: boolean;
  error?: boolean;
  height?: CSSProperties["height"];
  hovered?: boolean;
  id?: string;
  justifyContent?: CSSProperties["justifyContent"];
  onChange?:
    | ((value: any, statePath?: string) => void)
    | ((value: any) => void);
  onClick?: () => void;
  opacity?: number;
  primary?: boolean;
  required?: boolean;
  role?: Extract<AriaRole, "button" | "checkbox">;
  selected?: boolean;
  squared?: boolean;
  textBreak?: boolean;
  tooltipText?: string;
  white?: boolean;
  width?: CSSProperties["width"];
};

export function Chip({
  bold = false,
  children,
  chipMargin,
  chipPadding,
  className,
  colorOverride,
  disabled,
  error,
  height,
  hovered,
  id,
  justifyContent,
  onChange,
  onClick,
  opacity,
  primary = false,
  role,
  selected = false,
  squared,
  textBreak,
  tooltipText,
  white = true,
  width,
}: ChipProps) {
  return (
    <ChipBox
      aria-checked={role ? selected : undefined}
      aria-disabled={role ? disabled : undefined}
      backgroundColor={{
        white: !opacity && !selected && (disabled || error || white || primary),
        primary: selected,
      }}
      borderColor={{
        black: white,
        error,
        primary: selected || primary,
        disabled,
      }}
      borderHoverColor={{
        black: selected,
        error: error && !selected,
        primary: white,
      }}
      className={className}
      colorOverride={colorOverride}
      data-testid={id}
      height={height}
      hovered={hovered}
      id={id}
      justifyContent={justifyContent}
      margin={chipMargin}
      onClick={!disabled ? onClick : undefined}
      onKeyDown={!disabled ? handleKeyDown({ onConfirm: onClick }) : undefined}
      opacity={opacity}
      padding={chipPadding ?? (squared ? padding(0, 0.5) : undefined)}
      role={role}
      squared={squared}
      tabIndex={role ? 0 : undefined}
      width={width}
      onChange={onChange}
    >
      <Tooltip title={tooltipText}>
        <ChipText
          data-testid="chip-text"
          primary={!selected && primary}
          disabled={disabled}
          light={selected}
          bold={bold}
          textBreak={textBreak}
          style={{
            color: !selected && colorOverride ? colorOverride : undefined,
          }}
        >
          {children}
        </ChipText>
      </Tooltip>
    </ChipBox>
  );
}

export function PrintableChip({ children }: { children: ReactNode }) {
  return <Chip bold>{children}</Chip>;
}
